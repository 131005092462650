<template>
        <div v-if="list !== null && list !== undefined"  class=" mt-5 bg-white shadow-xl-white overflow-hidden sm:rounded-md">

            <div @click="toggleShowList" class="flex relative justify-center bg-white px-4 py-5 border-b border-gray-200 sm:px-6 cursor-pointer select-none">

                <div class="flex relative w-full justify-center">
                    <h3 v-if="!isEditing" class="text-xl leading-6 font-medium text-gray-900 ">
                        {{ listTitle}}
                    </h3>
                    <h3 v-if="!isEditingTitle && isEditing" @click.stop="listTitleClicked" class="text-xl leading-6 font-medium text-gray-900 ">
                        {{ listTitle}}
                    </h3>
                    <label @click.stop v-on-clickaway="finishEditingListTitle" v-if="isEditingTitle">
                        <input @focusout="finishEditingListTitle" v-model="newListTitle" @input="updateListTitle" type="text" class="block border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-base sm:leading-5">
                    </label>

                    <div class="absolute right-1 top-1">
                        <svg class="h-6 w-6 ml-4 transform" stroke="currentColor" fill="none" viewBox="0 0 24 24" :class="{'-rotate-180': !showList}">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                        </svg>
                    </div>
                </div>

                <div>

                </div>

            </div>

            <transition enter-class="transform opacity-0 scale-y-95"
                        enter-active-class="transition ease-out duration-100"
                        enter-to-class="transform opacity-100 scale-y-100"
                        leave-class="transform opacity-100 scale-y-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-to-class="transform opacity-0 scale-y-95">
                <div v-if="showList">
                    <ul>
                        <Container v-if="isEditing" group-name="col"
                                   @drop="(e) => onContentItemDrop(e)"
                                   :get-child-payload="getContentItemPayload"
                                   drag-class="bg-gray-200 rounded-md"
                                   drop-class="bg-gray-400"
                                   :drop-placeholder="dropPlaceholderOptions">

                            <Draggable v-for="(contentItem, index) in orderedItems" :key="contentItem.JID">
                                <CommunityContent @click.native="openDetails(contentItem)"
                                                  :index="index" :contentItem="contentItem" :isEditing="isEditing"/>
                            </Draggable>
                        </Container>


                        <CommunityContent v-else-if="doneResources !== null" v-for="(contentItem, index) in orderedItems" :key="contentItem.JID"
                                          v-on:contentClicked="openDetails(contentItem)" :index="index" :contentItem="contentItem"
                                          @contentDone="contentDone" @contentUndone="contentUndone"
                                          @saveContentItem="saveContentItem" @unSaveContentItem="unSaveContentItem"
                                          :isEditing="isEditing" :communityId="communityId" :communityName="communityName" :communityOwner="communityOwner"
                                          :doneProp="isDone(contentItem.JID)" :saved-prop="isSaved(contentItem.JID)"/>
<!--                                     :doneProp="doneResources.includes(contentItem.contentId)"/>-->

                    </ul>
                </div>
            </transition>

            <div v-if="isEditing" class="m-2 flex rounded-md shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"/>
                        </svg>
                    </div>
                    <label>
                        <input type="text" :id="`newContent-${listTitle}`" v-model="newContentInput" @keyup.enter="createContent" class="placeholder-gray-400  bg-gray-200 form-input block w-full focus:bg-gray-100 hover:bg-gray-300 rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="Enter a link or a name" />
                    </label>
                </div>
                <button @click="createContent" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-100 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"/>
                    </svg>
                    <span class="ml-2">Create</span>
                </button>
            </div>
        </div>
</template>



<script>
    import * as axios from '../../../axios-auth'
    import CommunityContent from './CommunityContent'
    import { v4 as uuidv4 } from 'uuid';
    import { Container, Draggable } from "vue-smooth-dnd";
    import _ from 'lodash';
    import {directive as onClickaway} from 'vue-clickaway'
    export default {
        name: "CommunityList",
        directives: {
            onClickaway
        },
        props: {
            listProp: {
                title: "",
                required: true,
            },
            isEditing: {
                type: Boolean,
                required: true,
            },
            boardID: {
                type: String,
                required: true,
            },
            communityId: {
                type: String,
            },
            communityName: {
                type: String
            },
            communityOwner: {
                type: String
            },
            doneResourcesProp: {
                type: Array,
                default: function () {return []}
            },
            savedResourcesProp: {
                type: Array,
                default: function () {return []}
            }
        },
        components:{
            communityContent: CommunityContent,
            Container: Container,
            Draggable: Draggable,

        },
        data() {
            return{
                list: {
                    title: "",
                    contentItems: [],
                },
                newListTitle: "",
                isEditingTitle: false,
                showList: true,
                draggedId: '',
                newContentInput: '',
                upperDropPlaceholderOptions: {
                    className: 'bg-gray-200',
                    animationDuration: '150',
                    showOnTop: true
                },
                savedResources: [],
                doneResources: [],
                dropPlaceholderOptions: {
                    className: 'bg-gray-500',
                    animationDuration: '150',
                    showOnTop: true
                },
                detailsContentItem: null,

            }
        },
        created(){
            console.log("Creating Lists")
            this.doneResources = this.doneResourcesProp
            this.savedResources = this.savedResourcesProp
            this.initializeList()
        },
        watch: {
            doneResourcesProp(){
                this.doneResources = this.doneResourcesProp
            },
            savedResourcesProp(){
                this.savedResources = this.savedResourcesProp
            },
            listProp(){
                this.initializeList()
            },
            contentItemsCount(){
                if(this.list.contentItems) {
                    return this.list.contentItems.length
                }
                return 0
            }
        },
        methods: {
            initializeList(){
                this.list = _.cloneDeep(this.listProp)
                if(!this.list.contentItems){
                    this.list.contentItems = []
                }
            },
            updateListTitle: _.debounce(function () {
                const oldTitle = this.list.title
                this.list.title = this.newListTitle
                this.$emit("updateListLocally", this.list)
                axios.jigo.put(`/v2/community/integrations/boards/updateListTitle/${this.boardID}/${this.list.JID}`, this.newListTitle)
                .then((resp) => {
                    if(resp.status !== 200 && resp.status !== 204) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.list.title = oldTitle
                        this.$emit("updateListTitleLocally", this.list)
                    }
                })
                .catch((err) => {
                    this.list.title = oldTitle
                    this.$emit("updateListLocally", this.list)
                    console.error("ERROR: updateListTitle FAILED", err)
                })
            }, 1000),
            finishEditingListTitle(){
                this.list.title = this.newListTitle
                this.isEditingTitle = false
            },
            listTitleClicked(){
                if(this.isEditing){
                    this.newListTitle = this.list.title
                    this.isEditingTitle = true
                }

            },
            saveContentItem(contentID){
                const savedResource = {
                    JID: contentID,
                    savedAt: new Date().toISOString(),
                };
                this.savedResources.splice(0, 0, savedResource);
                axios.jigo.put(`/v2/community/integrations/boards/content/save/${this.boardID}/${this.list.JID}/${contentID}`)
                .then((resp) => {
                    if(resp.status !== 200 && resp.status !== 201) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.savedResources.splice(0, 1)
                    }
                })
                .catch((err) => {
                    this.savedResources.splice(0, 1)
                    console.error("ERROR: contentSaved FAILED", err)
                })
            },
            unSaveContentItem(contentID){
                const doneIndex = _.findIndex(this.savedResources, (saved) => {
                    return saved.JID === contentID
                });
                const savedResource = this.savedResources.splice(doneIndex, 1)[0];
                axios.jigo.delete(`/v2/community/integrations/boards/content/save/${this.boardID}/${this.list.JID}/${contentID}`)
                .then((resp) => {
                    if(resp.status !== 200 && resp.status !== 204) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.savedResources.splice(0, 0, savedResource)
                    }

                })
                .catch((err) => {
                    this.savedResources.splice(0, 0, savedResource)
                    console.error("ERROR: contentUnSaved FAILED", err)
                })
            },
            contentDone(contentID){
                const doneResource = {
                    contentID: contentID,
                    doneAt: new Date().toISOString(),
                };
                this.doneResources.splice(0, 0, doneResource);

                axios.jigo.put(`/v2/community/integrations/boards/content/done/${this.boardID}/${this.list.JID}/${contentID}`)
                    .then((resp) => {
                        if (resp.status !== 201) {
                            this.doneResources.splice(0, 1)
                        }
                    })
                    .catch((err) => {
                        this.doneResources.splice(0, 1)
                        console.error("ERROR: contentDone FAILED", err)
                    })
            },
            contentUndone(contentID){
                const doneIndex = _.findIndex(this.doneResources, (done) => {
                    return done.contentID === contentID
                });
                const doneResource = this.doneResources.splice(doneIndex, 1)[0];
                this.done = false
                axios.jigo.delete(`/v2/community/integrations/boards/content/done/${this.boardID}/${this.list.JID}/${contentID}`)
                    .then((resp) => {
                        if (resp.status !== 204) {
                            this.doneResources.splice(0, 0, doneResource)
                        }
                    })
                    .catch((err) => {
                        this.doneResources.splice(0, 0, doneResource)
                        console.error("ERROR: contentUndone FAILED", err)
                    })

            },
            toggleShowList(){
                this.showList = !this.showList
            },
            openDetails(contentItem){
                this.$emit('openDetails', _.cloneDeep(contentItem), this.list.JID)
            },
            closeDetails(){
                this.detailsContentItem = null
            },
            getContentItemPayload(index){
                return this.list.contentItems[index]
            },
            goToContent(id){
                this.$router.push({name: 'content', params: {id: id}})
            },
            createContent(){

                if (!this.newContentInput) {
                    console.log("No string in input")
                    return
                }
                // let str = this.newContentInput.replace(/\s/g, '');
                let str = this.newContentInput
                if (str.length === 0){
                    console.log("No string in input")
                    return
                }
                const inputString = this.newContentInput
                this.newContentInput = ''

                let content = {
                    JID: uuidv4(),
                    level: "this.list.title",
                    mediaType: "Website",
                    sources: [],
                    createdAt: new Date().toISOString(),
                    order: this.contentItemsCount + 1
                };

                console.log("Creating content!")

                if (this.validURL(inputString)) {

                    let loader = this.$loading.show({
                        onCancel: this.onCancel,
                    });
                    content.contentUrl = inputString
                    const source = {
                        sourceName: '',
                        sourceUrl: inputString
                    };
                    content.sources = [source];

                    let openGraphRequest = {
                        url: inputString,
                    };

                    axios.jigo.post("/getOpenGraph", openGraphRequest)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log("The data from getOpenGraph is:", resp.data)
                            const hybridGraph = resp.data.hybridGraph
                            console.log("buballe")
                            content.title = hybridGraph.title;
                            console.log("Dubale")
                            if(hybridGraph.products) {
                                console.log("Yeah yeah")
                                content.title = hybridGraph.products[0].name
                            }
                            content.contentUrl = inputString
                            content.thumbnail = "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fcontent-placeholder.jpg?alt=media&token=bb79c947-2623-40af-8e57-f423442b417f";

                            if (hybridGraph.image !== "") {
                                content.thumbnail = hybridGraph.image
                            }

                            const mediaType = this.classifyType(resp.data)
                            content.mediaType = mediaType === "" ? "Website" : mediaType

                            const source = {
                                title : hybridGraph.site_name ? hybridGraph.site_name : "Source 1" ,
                                url: inputString
                            }
                            content.sources = [source]
                            content.description = hybridGraph.description
                        }
                        this.addContent(content)
                        loader.hide()
                    }).catch((err) => {
                        loader.hide()
                        console.error("ERROR: Fetching Open Graph details FAILED", err)
                    });
                } else {
                    content.title = inputString
                    this.addContent(content)
                }
            },
            classifyType(data){
                const courseSites = ["Coursera", "Udemy", "SkillShare", "edX", "Codecademy", "Laracasts"]
                const forums = ["Stack Overflow", "reddit"]
                let htmlInferred = data.htmlInferred
                // let hyperGraph = data.hyperGraph
                let openGraph = data.openGraph
                if(htmlInferred.products != null) {
                    if(htmlInferred.products[0].category === "Books"){
                        return "Book"
                    }
                }
                if(openGraph.site_name === "YouTube"){
                    switch (openGraph.type) {
                        case "profile":
                            return "YouTube Channel"
                        case "video.other":
                            return "YouTube Video"
                        case "website":
                            return "YouTube Series"
                        default:
                            return "Website"
                    }
                }
                if(courseSites.includes(openGraph.site_name)){
                    return "Course"
                }
                if(forums.includes(openGraph.site_name)){
                    return "Forum"
                }
                if(htmlInferred.url != null) {
                    if(htmlInferred.url.startsWith("https://www.udacity.com/course") ||
                        htmlInferred.url.startsWith("https://www.programiz.com/") ||
                            htmlInferred.url.startsWith("https://www.pluralsight.com")
                    ){
                        return "Course"
                    }
                }
                return "Website"
            },
            onCancel(){
                console.log('user canceled the loader')
            },
            addContent(content){
                console.log("Adding contentItem")
                this.$emit('addContent', content, this.list.JID)
                this.newContentInput = ''
            },
            validUR2L(str) {
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port andcommunity
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(str);
            },
            validURL(str)
            {
                const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
                return !!regexp.test(str);
            },
            log (...params) {
                console.log(...params)
            },
            onContentItemDrop(dropResult){
                const { removedIndex, addedIndex } = dropResult
                if (removedIndex !== null || addedIndex !== null) {
                    const newLevel = _.cloneDeep(this.list);
                    this.$emit('onContentDrop', dropResult, newLevel)
                }
            },
            isDone(contentID){
                for (let content of this.doneResources) {
                    if(content.contentID === contentID){
                        return true
                    }
                }
                return false
            },
            isSaved(contentID){
                for (let content of this.savedResources) {
                    if(content.JID === contentID){
                        return true
                    }
                }
                return false
            },
        },

        computed: {
            orderedItems(){
                return _.orderBy(this.list.contentItems, ['order'], ['asc'])
            },
            listTitle(){
                return this.list.title !== null ? this.list.title : ""
            }
        },
    }
</script>

<style scoped>
    .dragged{
        background-color: #2c3e50;
    }

    /*input[type="text"]{*/
    /*     padding-left: 0px;*/
    /*}*/

    input::placeholder{
        padding: 0;
    }
</style>



