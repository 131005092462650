<template>

    <div  v-if="communityBoard.JID !== null" class="pb-10">

        <div class="text-center mt-10">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-100 sm:text-4xl">
                {{ communityBoard.name }}
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-200 sm:mt-4">
                {{ communityBoard.description }}
            </p>
        </div>
        <div v-if="isCommunityEmpty && !isEditing" class="py-12 bg-white rounded-md mt-5">
            <div v-if="hasAccess">
                <edit-community-call v-on:editPressed="edit"></edit-community-call>
            </div>
            <div v-else>
                <community-in-progress></community-in-progress>
            </div>
        </div>

        <div v-if="!isCommunityEmpty || isEditing">
            <div class=" mt-5 flex flex-wrap items-center justify-end ">
                <div v-if="hasAccess" class="hidden sm:block flex lg:mt-0 lg:ml-4">
                    <span v-if="!isEditing" class=" sm:block shadow-sm sm:mr-3 rounded-md">
                        <button @click="edit" type="button" class="w-32 justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700 transition duration-150 ease-in-out">
                            <svg class="-ml-1 mr-2 h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                            Edit Board
                        </button>
                    </span>
                    <span v-if="isEditing" class="sm:mr-3 sel sm:block shadow-sm rounded-md">
                        <button @click="doneEditing" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700 transition duration-150 ease-in-out">
                            <svg class="-ml-1 mr-2 h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                            Finish Editing
                        </button>
                    </span>
                </div>
            </div>

            <div v-if="orderedLists !== null" class="flex flex-col xl:flex-row justify-evenly relative">
                <div class="w-full">
                    <Container v-if="isEditing" group-name="lists"
                               @drop="(e) => onListDrop(e)"
                               :get-child-payload="getListPayload"
                               drag-class="bg-gray-200 rounded-md"
                               drop-class="bg-gray-400"
                               :drop-placeholder="dropPlaceholderOptions">

                        <Draggable v-for="list in orderedLists" :key="list.JID">
                            <CommunityList  class="rounded-md" @updateListLocally="updateListLocally"
                                      @openDetails="openDetails" @updateLevel="updateLevel"
                                      @updateContentInputs="updateContentInputs" @addContent="addContent" @onContentDrop="onContentDrop"
                                      :doneResourcesProp="doneResources" :savedResourcesProp="savedResources" :isEditing="isEditing"
                                      :communityId="communityBoard.JID" :list-prop="list"
                                      :communityOwner="communityOwner" :communityName="communityName" :boardID="boardID"/>
                        </Draggable>
                    </Container>

                    <CommunityList v-else v-for="list in orderedLists" :key="list.JID" class="rounded-md"
                              @openDetails="openDetails" @updateLevel="updateLevel"
                              @updateContentInputs="updateContentInputs" @addContent="addContent" @onContentDrop="onContentDrop"
                              :doneResourcesProp="doneResources" :savedResourcesProp="savedResources" :isEditing="isEditing"
                              :communityId="communityBoard.JID" :list-prop="list"
                              :communityOwner="communityOwner" :communityName="communityName" :boardID="boardID"/>
                </div>

<!--                <div class="w-full">-->
<!--                    <CommunityList v-if="generalList !== null" class="rounded-md xl:ml-5 topSticky"-->
<!--                              @openDetails="openDetails" @updateLevel="updateLevel"-->
<!--                              @updateContentInputs="updateContentInputs" @addContent="addContent" @onContentDrop="onContentDrop"-->
<!--                              :doneResourcesProp="doneResources" :savedResourcesProp="savedResources" :isEditing="isEditing"-->
<!--                              :communityId="communityBoard.JID" :list="generalList"-->
<!--                              :communityOwner="communityOwner" :communityName="communityName" :boardID="boardID"/>-->
<!--                </div>-->
            </div>

            <div v-if="isEditing" class="bg-white rounded-md p-2 mb-6 mt-10">
                <div>
                    <label for="newPost" class="sr-only">New List</label>
                    <div class="relative rounded-md shadow-sm">
                        <input type="text" v-model="newListTitle" @keypress.enter="createNewList" id="newPost"
                               class="form-input border-gray-500 bg-gray-200 hover:bg-white hover:border-indigo-600 block border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:bg-white border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                               placeholder="Create A New List">
                    </div>
                </div>
            </div>
        </div>


        <contentDetails v-if="isEditing" :contentItem="detailsContentItem" @deleteContentItem="deleteContentItem"
                        @updateContentInputs="updateContentInputs" :listID="detailsListID"
                        @updateContentSource="updateContentSource"
                        @newContentSource="newContentSource" @deleteContentSource="deleteContentSource"
                        :open="isDetailsOpen" v-on:close="isDetailsOpen = false" class="z-30 fixed overlay"/>

        <contentDisplay v-else :doneResources="doneResources" :contentItem="detailsContentItem" :open="isDetailsOpen"
                        v-on:close="isDetailsOpen = false" class="z-30 " :communityName="communityName" :communityOwner="communityOwner" />

        <successNotification :open="showSuccessSave">
            <template v-slot:header>
                Saved!
            </template>
            <template v-slot:text>
                Board saved to cloud successfully.
            </template>
        </successNotification>

        <save-faile-notification notificationType="alert" :open="showFailedSave">
            <template v-slot:header>
                Save Failed!
            </template>
            <template v-slot:text>
                Failed to save changes. Please check your connection. Server might be unreachable.
            </template>
        </save-faile-notification>


    </div>
</template>




<script>
    import CommunityInProgress from './/components/CommunityInProgress'
    import SuccessNotification from '../../components/ActionNotification'
    import EditBoardCTA from './components/EditBoardCTA'
    import CommunityList from './components/CommunityList'
    import ContentDetails from "./components/ContentDetails"
    import ContentDisplay from "./components/ContentDisplay"
    import { Container, Draggable } from "vue-smooth-dnd";
    import _ from 'lodash';
    import * as axios from '../../axios-auth'
    import {mapGetters} from 'vuex'

    export default {
        name: "CommunityBoard",
        data() {
            return {
                dropPlaceholderOptions: {
                    className: 'bg-jiruto-perfume mt-5 rounded-md',
                    animationDuration: '150',
                    showOnTop: true
                },
                detailsListID: null,
                showFailedSave: false,
                dropCount: 0,
                changeStack: [],
                dropTracker: {
                    dropCount: 0,
                    shouldUpdate: false,
                },
                lastChange: {
                    changeType: null,
                    destinationList: null,
                    originList: null,
                    originalContentItem: null,
                    destinationOrder: null,
                    newContentItem: null,
                },
                listOrder: {
                    "Beginner": 1,
                    "Intermediate": 2,
                    "Advanced": 3,
                    "General Resources": 4,
                },
                communityBoard: {
                    contentItemsCount: null,
                    createdAt: null,
                    description: null,
                    lastUpdated: null,
                    lists: [],
                    JID: null
                },
                loaded: false,
                showSuccessSave: false,
                isEditing: false,
                // openDetails: false,
                isDetailsOpen: false,
                detailsContentItem: null,
                doneResources: [],
                savedResources: [],
                moreOpen: false,
                resetModal: false,
                draggedIndex: null,
                newListTitle: "",
                // boardID: null,
            }
        },
        components: {
            Container,
            Draggable,
            CommunityInProgress,
            CommunityList: CommunityList,
            // resetModal: AlertModal,
            contentDetails: ContentDetails,
            contentDisplay: ContentDisplay,
            successNotification: SuccessNotification,
            saveFaileNotification: SuccessNotification,
            editCommunityCall: EditBoardCTA,
        },
        props: {
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
            boardID: {
                type: String,
                required: true,
            },

        },
        created(){
            this.$emit('viewLoading')
            this.fetchBoard()
            this.fetchDoneResources()
            this.fetchSavedResources()
        },

        methods: {
            updateListLocally(newList){
                let listIndex =  _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === newList.JID
                });
                this.communityBoard.lists.splice(listIndex, 1, newList)
            },
            removeListLocally(listID){
                let indexToRemove =  _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                });
                const removedList = this.communityBoard.lists.splice(indexToRemove, 1)[0]
                for(let list of this.communityBoard.lists) {
                    if(list.order > removedList.order) {
                        list.order -= 1
                    }
                }
            },
            addListLocally(newList){
                for (let list of this.communityBoard.lists){
                    if (list.order >= newList.order){
                        list.order += 1
                    }
                }
                this.communityBoard.lists.push(newList)
            },
            getListPayload(index){
                return this.orderedLists[index]
            },
            moveListLocally(list, newOrder) {
                this.removeListLocally(list.JID)
                list.order = newOrder;
                this.addListLocally(list)
            },
            onListDrop(dropResult){
                let {removedIndex, addedIndex, payload } = dropResult
                this.moveListLocally(payload, addedIndex + 1)
                let moveListRequest = {
                    listID: payload.JID,
                    destinationBoardID: this.boardID,
                    destinationOrder: addedIndex + 1,
                }
                axios.jigo.post(`/v2/community/integrations/boards/moveList/${this.boardID}`, moveListRequest)
                .then( (resp) => {
                    if (resp.status !== 200) {
                        this.moveListLocally(payload, removedIndex + 1)
                        console.log("Unwanted status: ", resp.status, resp)
                    }
                }).catch((err) => {
                    this.moveListLocally(payload, removedIndex + 1)
                    console.error("ERROR: updateUsername FAILED", err)
                })
            },
            createNewList(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.post(`/v2/community/integrations/boards/newList/${this.boardID}`, this.newListTitle)
                .then( (resp) => {
                    if (resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        loader.hide()
                        return
                    }
                    const list = resp.data
                    this.communityBoard.lists.push(list)
                    this.newListTitle = ""
                    loader.hide()
                }).catch((err) => {
                    loader.hide()
                    console.error("ERROR: updateUsername FAILED", err)
                })
            },
            createNewBoard(board){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.post(`/v2/community/integrations/boards/newBoard/${this.communityOwner}/${this.communityName}`, board)
                .then( (resp) => {
                    if (resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                        loader.hide()
                        return
                    }
                    this.isCreatingNewBoard = false
                    const board = resp.data
                    loader.hide()
                    this.$router.push({name: 'communityBoard', params: {boardID: board.JID}})
                }).catch((err) => {
                    loader.hide()
                    console.error("ERROR: updateUsername FAILED", err)
                })
            },
            fetchBoard(){
                let loader = this.$loading.show({
                    onCancel: this.onLoaderCancel,
                });
                console.log("BoardID", this.boardID)
                axios.jigo.get(`/v2/community/integrations/boards/getBoard/${this.boardID}`).then((resp) => {
                    let board = resp.data
                    if (board.lists === null || board.lists === undefined) {
                        board.lists = []
                    }
                    this.communityBoard = board
                    loader.hide()
                    this.$emit('viewLoaded')
                }).catch((err) => {
                    console.error("Error in fetching the board:", err)
                    loader.hide()
                })
            },
            fetchDoneResources(){
                axios.jigo.get(`/v2/user/boards/doneContentItems/${this.boardID}`).then((resp) =>{
                    this.doneResources = resp.data
                    console.log("DoneResources", resp.data)
                }).catch((err) => {
                    console.error("Error in fetching the progress:", err)
                })
            },
            fetchSavedResources(){
                axios.jigo.get(`/v2/user/boards/savedContentItems/${this.boardID}`).then((resp) =>{
                    if(resp.status !== 200){
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    this.savedResources = resp.data
                    console.log("Saved Resources", resp.data)
                }).catch((err) => {
                    console.error("Error: fetchSavedResources FAILED", err)
                })
            },
            revertLastChange(){
                // Remove from destination list
                let destinationList = _.cloneDeep(this.communityBoard.lists.find((list) => {
                    return list.JID === this.lastChange.destinationList
                }))
                const originItem = _.cloneDeep(this.lastChange.originalContentItem)

                destinationList = this.removeContentItem(originItem.JID, destinationList)
                this.updateLevel(destinationList)
                // Add back to the origin list
                let originList = _.cloneDeep(this.communityBoard.lists.find((list) => {
                    return list.JID === this.lastChange.originList
                }))
                originList = this.addContentItem(originItem, originList, originItem.order - 1)
                this.updateLevel(originList)
            },
            onContentDrop(dropResult, list){
                let {removedIndex, addedIndex, payload } = dropResult
                let newLevel = _.cloneDeep(list);
                if (removedIndex !== null) {
                    this.dropCount += 1;
                    this.lastChange.originalContentItem = _.cloneDeep(dropResult.payload)
                    this.lastChange.originList = list.JID;
                    newLevel = this.removeContentItem(payload.JID, newLevel)
                }

                if (addedIndex !== null) {
                    this.dropCount += 1;
                    let newPayload = _.cloneDeep(payload);

                    const order = addedIndex + 1;
                    newPayload.order = order;
                    this.lastChange.destinationOrder = order;

                    newPayload.level = list.title;
                    newLevel = this.addContentItem(newPayload, newLevel, addedIndex)

                    this.lastChange.destinationList = list.JID
                }
                this.updateLevel(newLevel);
            },
            moveContentInDB(request){
                return new Promise((resolve, reject) => {
                    axios.jigo.post(`/v2/community/integrations/boards/content/move/${this.boardID}`, request)
                        .then((resp) => {
                        if(resp.status === 201) {
                            resolve()
                        } else {
                            console.error("ERROR: Can't update db")
                            reject()
                        }
                    }).catch((err) => {
                        console.error("ERROR: Can't update db", err)
                        reject(err)
                    })
                })
            },
            removeContentItem(contentJID, newLevel){
                let indexToRemove =  _.findIndex(newLevel.contentItems, (item) => {
                    return item.JID === contentJID
                });
                newLevel.contentItems.splice(indexToRemove, 1);
                for (let i = 0 ; i< newLevel.contentItems.length; i++){
                    if (newLevel.contentItems[i].order > indexToRemove + 1){
                        newLevel.contentItems[i].order -= 1
                    }
                }
                return newLevel
            },
            addContentItem(contentItem, list){
                for (let i = 0 ; i< list.contentItems.length; i++){
                    if (list.contentItems[i].order >= contentItem.order){
                        list.contentItems[i].order += 1
                    }
                }
                list.contentItems.splice(contentItem.order - 1, 0, contentItem)
                return list
            },
            updateContentInputs(contentItem, listID) {
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentItem.JID
                });
                this.communityBoard.lists[listIndex].contentItems.splice(contentIndex, 1, _.cloneDeep(contentItem));

                const timeNow = new Date().toISOString();
                const newContentItem = {
                    JID: contentItem.JID,
                    title: contentItem.title,
                    description: contentItem.description,
                    mediaType: contentItem.mediaType,
                    lastUpdated: timeNow
                };
                axios.jigo.post(`/v2/community/integrations/boards/content/updateInputs/${this.boardID}/${listID}/${contentItem.JID}`, newContentItem)
                    .then().catch((err) => {
                        console.error("ERROR: updating content inputs FAILED", err)
                })
            },
            updateContentSource(source, contentID, listID){
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentID
                });
                const sourceIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems[contentIndex].sources, (src) => {
                    return src.JID === source.JID
                });
                this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 1, _.cloneDeep(source));
                axios.jigo.post(`/v2/community/integrations/boards/content/updateSource/${this.boardID}/${listID}/${contentID}`, source)
                    .then((resp) => {
                        if (resp.status !== 201 ){
                            //TODO: check if needs reverting locally upon failure
                            console.error("ERROR: updating source FAILED")
                            // this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 1, _.cloneDeep(source))
                            // this.detailsContentItem = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex])
                        }
                    }).catch((err) => {
                    console.error("ERROR: updating content source FAILED", err)
                })
            },
            addContent(contentItem, listID){
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                let contentClone = _.cloneDeep(contentItem)
                if(!this.communityBoard.lists[listIndex].contentItems){
                    this.communityBoard.lists[listIndex].contentItems = []
                }
                this.communityBoard.lists[listIndex].contentItems.push(contentClone)
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentItem.JID
                });
                axios.jigo.post(`/v2/community/integrations/boards/content/new/${this.boardID}/${listID}`, contentItem)
                    .then((resp) => {
                        if (resp.status === 201) {
                            contentClone = _.cloneDeep(resp.data)
                            this.communityBoard.lists[listIndex].contentItems.splice(contentIndex, 1, contentClone)
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: addContent send to cloud failed", err);
                        this.showFailedSave = true;
                        setTimeout(() => this.showFailedSave = false, 4000)
                })
            },
            deleteContentItem(contentID, listID){
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentID
                });
                const contentItem = this.communityBoard.lists[listIndex].contentItems.splice(contentIndex, 1)[0]

                for (let i = 0 ; i< this.communityBoard.lists[listIndex].contentItems.length; i++){
                    if (this.communityBoard.lists[listIndex].contentItems[i].order > contentItem.order){
                        this.communityBoard.lists[listIndex].contentItems[i].order -= 1
                    }
                }
                axios.jigo.post(`/v2/community/integrations/boards/content/delete/${this.boardID}/${listID}`, contentItem)
                    .then((resp) => {
                        if (resp.status !== 200){
                            this.revertDelete(listIndex, contentIndex, contentItem)
                            this.showFailedSave = true
                            setTimeout(() => this.showFailedSave = false, 4000)
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: deleteContentItem FAILED - no communication", err)
                        this.revertDelete(listIndex, contentIndex, contentItem)
                        this.showFailedSave = true
                        setTimeout(() => this.showFailedSave = false, 4000)
                    })
            },
            revertDelete(listIndex, contentIndex, contentItem){
                for (let dex = 0 ; dex< this.communityBoard.lists[listIndex].contentItems.length; dex++){
                    if (this.communityBoard.lists[listIndex].contentItems[dex].order >= contentItem.order){
                        this.communityBoard.lists[listIndex].contentItems[dex].order += 1
                    }
                }
                this.communityBoard.lists[listIndex].contentItems.splice(contentIndex, 0, contentItem)
            },
            newContentSource(source, contentID, listID) {
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentID
                });
                this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.push(source)

                const sourceIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems[contentIndex].sources, (src) => {
                    return src.JID === source.JID
                });

                axios.jigo.post(`/v2/community/integrations/boards/content/newSource/${this.boardID}/${listID}/${contentID}`, source)
                    .then((resp) => {
                        if (resp.status === 201) {
                            const newSource = _.cloneDeep(resp.data)
                            // let sourceClone = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex].source[sourceIndex])
                            this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 1, newSource)
                            this.detailsContentItem = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex])
                        }else {
                            console.error("Couldn't create a new source")
                        }
                    }).catch((err) => {
                    console.error("Couldn't create a new source", err)
                })
            },
            deleteContentSource(sourceID, contentID, listID){
                const listIndex = _.findIndex(this.communityBoard.lists, (list) => {
                    return list.JID === listID
                })
                const contentIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems, (item) => {
                    return item.JID === contentID
                });
                const sourceIndex = _.findIndex(this.communityBoard.lists[listIndex].contentItems[contentIndex].sources, (source) => {
                    return source.JID === sourceID
                });

                const deletedSource = this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 1)[0]
                this.detailsContentItem = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex])

                axios.jigo.delete(`/v2/community/integrations/boards/content/deleteSource/${this.boardID}/${listID}/${contentID}/${sourceID}`)
                    .then((resp) => {
                        if (resp.status !== 201) {
                            this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 0, deletedSource)
                            this.detailsContentItem = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex])
                            console.error("Couldn't delete the source")
                        }
                    }).catch((err) => {
                    this.communityBoard.lists[listIndex].contentItems[contentIndex].sources.splice(sourceIndex, 0, deletedSource)
                    this.detailsContentItem = _.cloneDeep(this.communityBoard.lists[listIndex].contentItems[contentIndex])
                    console.error("Couldn't delete the source", err)
                })

            },
            onLoaderCancel(){
                console.error("Loader has been cancelled")
            },
            doneEditing(){
                this.isEditing = false
            },
            closeResetModal(){
                this.resetModal = false
            },
            edit() {
                this.isEditing = true
            },
            updateLevel(updatedList){
                let index =  _.findIndex(this.communityBoard.lists, (list) => {
                    return updatedList.JID === list.JID
                });
                this.communityBoard.lists.splice(index, 1);
                this.communityBoard.lists.push(updatedList)
            },
            openDetails(contentItem, listID){
                this.detailsContentItem = _.cloneDeep(contentItem)
                this.isDetailsOpen = true
                this.detailsListID = listID
            },
        },
        computed: {
            ...mapGetters(['getCommunities', 'getUsername']),
            orderedLists(){
                if(this.communityBoard.lists === null) {
                    return null
                }
                let listsCopy = _.cloneDeep(this.communityBoard.lists)
                if (!this.isEditing) {
                    let nonEmpty =  _.filter(this.communityBoard.lists, (list) => {
                        if(!list.contentItems){
                            return false
                        }
                        return list.contentItems.length !== 0
                    })
                    return _.orderBy(nonEmpty, ['order'], ['asc'])
                } else {
                    return _.orderBy(listsCopy, ['order'], ['asc'])
                }
            },
            generalList(){
                if(this.communityBoard.lists === null) {
                    return null
                }
                let index =  _.findIndex(this.communityBoard.lists, (list) => {
                    return list.title.toLowerCase() === "general resources"
                });
                return this.communityBoard.lists[index]
            },
            fullCommunityName() {
                return `${this.communityOwner}/${this.communityName}`
            },
            hasAccess(){
                return (this.getCommunities.includes(this.fullCommunityName) || this.isOwner)
            },
            isOwner(){
                return (this.communityOwner === this.getUsername)
            },
            isCommunityEmpty(){
                // const communityLevels = this.communityBoard.contentItemsCount
                if (this.communityBoard.lists === null) {
                    return true
                }
                return (this.communityBoard.contentItemsCount === 0)
            },
        },
        watch: {
            boardID(){
                this.$emit('viewLoading')
                this.fetchBoard()
                this.fetchDoneResources()
                this.fetchSavedResources()
            },
            dropCount(){
                if (this.dropCount >= 2){
                    const moveRequest = {
                        contentID: this.lastChange.originalContentItem.JID,
                        destinationOrder: this.lastChange.destinationOrder,
                        destinationListID: this.lastChange.destinationList,
                    };
                    this.moveContentInDB(moveRequest).then().catch(() => {
                        this.revertLastChange()
                        this.showFailedSave = true
                        setTimeout(() => this.showFailedSave = false, 4000)
                    });
                    this.dropCount = 0
                }
            },
            isDetailsOpen(newVal){
                if(newVal){
                    document.body.classList.add('overflow-hidden')
                } else {
                    document.body.classList.remove('overflow-hidden')
                }
            }
        }
    }
</script>

<style scoped>
    .topSticky {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 10px;
    }
</style>




<!--                    <span class="ml-3 relative shadow-sm rounded-md hidden sm:hidden">-->
<!--                        <button @click="moreOpen = !moreOpen" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:shadow-outline-gray focus:border-gray-800 transition duration-150 ease-in-out">-->
<!--                            More-->
<!--                            <svg class="-mr-1 ml-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />-->
<!--                            </svg>-->
<!--                        </button>-->

<!--                        <transition enter-class="transform opacity-0 scale-95"-->
<!--                                    enter-active-class="transition ease-out duration-200"-->
<!--                                    enter-to-class="transform opacity-100 scale-100"-->
<!--                                    leave-class="transform opacity-100 scale-100"-->
<!--                                    leave-active-class="transition ease-in duration-75"-->
<!--                                    leave-to-class="transform opacity-0 scale-95"-->
<!--                        >-->
<!--                            <div v-if="moreOpen" class="origin-top-left absolute left-0 mt-2 -ml-1 w-48 rounded-md shadow-lg">-->
<!--                                <div class="py-1 rounded-md bg-white shadow-xs">-->
<!--                                    <a href="#" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Edit</a>-->
<!--                                    <a href="#" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">View</a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </transition>-->
<!--                    </span>-->
