<template>

    <div>
        <div class="bg-white shadow overflow-hidden sm:rounded-md cursor-pointer">
            <div class="border-t border-gray-200">
                <div class="block" >
                    <div class="flex items-center items-stretch ">
                        <div v-if="!isEditing" class=" flex items-center bg-gray-200 px-4 ">

                            <div @click="progressPressed" class="bg-white h-8 w-8 rounded-full border-2 border-gray-400 text-white flex items-center justify-center hover:bg-green-200" :class="{'bg-jiruto-successSecondary border-jiruto-successSecondary hover:bg-jiruto-successSecondary': isDone}">
                                <svg class="w-6 h-6" v-if="isDone" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/></svg>
                            </div>

                        </div>
                        <div @click="contentClicked"
                             class=" px-4 py-4 sm:px-6 min-w-0 max-w-50 flex-1 flex sm:flex sm:items-center sm:justify-between truncate hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out ">
                            <div class="truncate pr-2 flex-grow">
                                <div class="text-md leading-5 font-medium text-indigo-600 truncate ">
                                    <p class="truncate">
                                        {{ contentItem.title }}
                                    </p>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="flex items-center text-sm leading-5 text-gray-500">
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
<!--                                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"/>-->
                                            <path d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z" fill="#4A5568"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"/>
<!--                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3C10.2652 3 10.5196 3.10536 10.7071 3.29289L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L10 5.41421L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289L9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6.29289 12.2929C6.68342 11.9024 7.31658 11.9024 7.70711 12.2929L10 14.5858L12.2929 12.2929C12.6834 11.9024 13.3166 11.9024 13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929Z"/>-->
                                        </svg>
                                        <span>
                                            {{ contentItem.mediaType }}
                                            <!--                    <time datetime="2020-01-07">January 7, 2020</time>-->
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="contentItem.thumbnail" class="mt-4 flex-shrink-0 sm:mt-0">
                                <div class="flex overflow-hidden rounded-md bg-purple-100">
                                    <img class="inline-block h-16 w-16 rounded-md text-white shadow-solid object-cover" :src="contentItem.thumbnail.replace('https', 'http').replace('http', 'https')" alt="" />
                                </div>
                            </div>
                            <div v-if="!isEditing" @click.stop="savePressed" class="ml-5 w-f5 flex-shrink-0 flex items-center text-gray-400 p-1" :class="{'text-gray-400': !isSaved, 'text-jiruto-zotSecondary': isSaved}">
<!--                                <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>-->
<!--                                </svg>-->

                                <svg xmlns="http://www.w3.org/2000/svg" :fill="fill" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <alertNotification :open="showAlertNotification" notificationType="alert">
            <template v-slot:header>
                Failed to toggle 'Done' Button!
            </template>
            <template v-slot:text>
                Please refresh the page or try again later.
            </template>
        </alertNotification>


    </div>

</template>

<script>
    import {mapGetters} from 'vuex'
    import ActionNotification from '../../../components/ActionNotification'
    // const fb = require('../../../firebaseConfig')
    import _ from 'lodash';
    export default {
        name: "CommunityContent",

        components: {
            alertNotification: ActionNotification,
        },
        props: {
            contentItem: {
                required: true,
            },
            index: {
                required: true,
            },
            isEditing: {
                required: true,
            },
            communityId: {
                type: String,
            },
            communityName: {
                type: String
            },
            communityOwner: {
                type: String
            },
            doneProp: {
                type: Boolean
            },
            savedProp: {
                type: Boolean,
            }

        },
        created() {
            this.isDone = this.doneProp
            this.isSaved = this.savedProp
            this.updatedContentItem = _.cloneDeep(this.contentItem)
        },
        data() {
            return {
                showAlertNotification: false,
                isDone: false,
                isSaved: false,
                // openDetails: false,
                updatedContentItem: {
                    title: null,
                    level: null,
                    authors: null,
                    descrip: null,
                    mediaType: null,
                    thumbnail: null,
                    order: null,
                    contentId: null,
                    contentUrl: null,
                    sources: null,
                    levelOrder: null,
                }
            }
        },
        methods: {
            savePressed(){
                this.savedProp ? this.unSaveContentItem() : this.saveContentItem()
            },
            saveContentItem(){
                this.$emit('saveContentItem', this.contentItem.JID)
            },
            unSaveContentItem(){
                this.$emit('unSaveContentItem', this.contentItem.JID)
            },
            progressPressed(){
                this.doneProp ? this.contentUndone() : this.contentDone()
            },
            contentDone(){
                this.$emit('contentDone', this.contentItem.JID)
            },
            contentUndone(){
                this.$emit('contentUndone', this.contentItem.JID)

            },
            contentClicked(){
                this.$emit("contentClicked")
            },
            formatThumbnail(){

            },
            urlSaved(newContent){
                this.updatedContentItem = newContent

            }
        },
        computed: {
            ...mapGetters(['getUsername']),
            fill(){
                if (this.isSaved) {
                    return "currentColor"
                } else {
                    return "none"
                }
            }
        },
        watch: {
            doneProp(newVal){
                this.isDone = newVal
            },
            savedProp(newVal){
                this.isSaved = newVal
            }
        }

    }
</script>

<style scoped>

</style>
