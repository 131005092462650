<template>
    <div  v-if="open" class="fixed z-2000 modal-mask overflow-y-auto bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

        <transition enter-class="transition opacity-0"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100"
                    leave-class="transition opacity-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0"
        >
            <div v-if="open" class="fixed inset-0 transition-opacity">
                <div class="absolute overflow-y-scroll inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>


        <transition enter-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >

            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div v-if="open" v-on-clickaway="close" class=" bg-white rounded-lg  overflow-hidden shadow-xl transform transition-all sm:rounded-lg my-5 ">

                    <!--                <div class="bg-gray-300 shadow overflow-hidden  sm:rounded-lg">-->
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Content Information
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Here you can find all the details about the content. Use the links at the bottom to reach the content sources and start learning.
                        </p>
                    </div>
                    <div class="px-4 py-5 sm:p-0">
                        <dl>
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt class="text-sm leading-5 font-medium text-gray-500">
                                    Title
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{ contentItem.title }}
                                </dd>
                            </div>

                            <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt class="text-sm leading-5 font-medium text-gray-500">
                                    Description
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                    <p style="white-space: pre-line">
                                        {{ contentItem.description }}
                                    </p>
                                </dd>
                            </div>

                            <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt class="text-sm leading-5 font-medium text-gray-500">
                                    Content Type
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{ contentItem.mediaType }}
                                </dd>
                            </div>
                            <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt class="text-sm leading-5 font-medium text-gray-500">
                                    Content Level
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{ contentItem.level }}
                                </dd>
                            </div>
                            <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 border-t-4 sm:border- sm:border-gray-400 sm:px-6 sm:py-5">
                                <dt class="text-lg leading-5 mt-4 font-bold text-black">
                                    Go To The Content:
                                </dt>
                                <dd class="mt-2 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                    <ul class=" border-gray-300 rounded-md">
                                        <li  v-for="(source, index) in updatedContentItem.sources" :key="index" @click="goToSource(source.url)" class="pl-3 pr-4 py-3 flex items-center justify-between text-white text-sm leading-5 cursor-pointer bg-jiruto-zotMain rounded-md mb-1">
                                            <div class="w-0 flex-1 flex items-center rounded-md">
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M11 3C10.4477 3 10 3.44772 10 4C10 4.55228 10.4477 5 11 5H13.5858L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L15 6.41421V9C15 9.55228 15.4477 10 16 10C16.5523 10 17 9.55228 17 9V4C17 3.44772 16.5523 3 16 3H11Z"/>
                                                    <path d="M5 5C3.89543 5 3 5.89543 3 7V15C3 16.1046 3.89543 17 5 17H13C14.1046 17 15 16.1046 15 15V12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V15H5V7L8 7C8.55228 7 9 6.55228 9 6C9 5.44772 8.55228 5 8 5H5Z"/>
                                                    <!--                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"/>-->
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate">
                                                    {{(source.title == null || source.title === "") ? `Source ${index + 1}` : source.title }}
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <button class="font-medium  text-indigo-200 hover:text-indigo-300 transition duration-150 ease-in-out">
                                                    Open
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <div class="px-4 py-5 sm:p-0 mx-6 my-3">
                        <span class="mt-6 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1 justify-center">
                            <button @click="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                              close
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {directive as onClickaway} from 'vue-clickaway'
    export default {
        name: "ContentDisplay",
        directives: {
            onClickaway
        },
        props: ['open', 'contentItem', 'communityOwner', 'communityName'],
        data() {
            return {
                updatedContentItem: {
                    title: null,
                    level: null,
                    authors: null,
                    description: null,
                    mediaType: null,
                    thumbnail: null,
                    contentId: null,
                    contentUrl: null,
                    sources: null,
                }
            }
        },
        created() {
            this.updatedContentItem = this.contentItem
        },
        watch: {
            contentItem (newContentItem){
                this.updatedContentItem = _.cloneDeep(newContentItem)
            }
        },
        methods: {
            close() {
                this.$emit('close')
            },
            goToSource(sourceUrl){
                this.$analytics.logEvent("Resource_Clicked", {community_name: this.communityName, community_owner: this.communityOwner, content_title: this.updatedContentItem})
                this.$mixpanel.track("Resource_Clicked", {community_name: this.communityName, community_owner: this.communityOwner, content_title: this.updatedContentItem});
                window.open(sourceUrl, '_blank');
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed !important;
        z-index: 2000 !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
        overflow-y: auto !important;
        webkit-transform: translate3d(0,0,0) !important;
        background-color: rgba(255, 255, 255, 0.8) !important;
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        transition: opacity .3s ease;
    }

</style>
