<template>
    <div   v-if="open" class="fixed modal-mask w-full h-full bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center ">

        <transition enter-class="transition opacity-0"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100"
                    leave-class="transition opacity-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0"
        >

            <div v-if="open" class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

        </transition>


        <transition enter-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div v-if="open" v-on-clickaway="close" class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:rounded-lg sm:p-6">
                <div class="md:grid md:grid-cols-1 md:gap-6">
                    <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Content Details</h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            The more you specify the easier it will be for future learners to find and follow it.
                        </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-1">
                        <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-6 sm:col-span-6">
                                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">Title</label>
                                <input  @input="updateInputs" v-model="updatedContentItem.title" id="first_name" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                            </div>
                            <div class="col-span-6 sm:col-span-6">
                                <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>
                                <textarea @input="updateInputs" v-model="updatedContentItem.description" id="description" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 h-24" placeholder="My awesome content description"/>
                            </div>

                            <div  class="col-span-6 sm:col-span-3">
                                <label for="country" class="block text-sm font-medium leading-5 text-gray-700">Media Type</label>
                                <select @input="updateInputs" v-model="updatedContentItem.mediaType" id="country" class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                    <option>Jiruto Community</option>
                                    <option>Book</option>
                                    <option>Course</option>
                                    <option>Blog</option>
                                    <option>Forum</option>
                                    <option>Website</option>
                                    <option>YouTube Channel</option>
                                    <option>YouTube Series</option>
                                    <option>YouTube Video</option>
                                    <option>Person</option>
                                    <option>Podcast</option>
                                    <option>Application</option>
                                </select>
                            </div>
                            <div id="sources" class="col-span-6 sm:col-span-6 md:grid md:grid-cols-2 md:gap-3 ">
                                <!--                                                        <label for="sources" class="col-span-2 mt-2 block text-sm font-medium leading-5 text-gray-700 text-lg">Sources</label>-->
                                <div class="col-span-6 sm:col-span-6 md:grid md:grid-cols-2 md:gap-3 mt-3 " v-for="(source,index) in updatedContentItem.sources" :key="index">
                                    <div class="col-span-1 sm:col-span-1">
                                        <label  class="block text-sm font-medium leading-5 text-gray-700">Source Name</label>
                                        <input v-model="source.title" @input="updateContentSource(source)" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="http://example.com"/>
                                        <!--                                                                <input @focusout="$emit('urlSaved', updatedContentItem)" v-model="source.sourceName" id="sourceLink" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="http://example.com"/>-->
                                    </div>

                                    <div class="col-span-1 sm:col-span-1">
                                        <label :for="`source-${index}`" class="block text-sm font-medium leading-5 text-gray-700">Source Link</label>
                                        <input :id="`source-${index}`" @input="updateContentSource(source)" v-model="source.url"  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="http://example.com"/>
                                        <!--                                                                <input @focusout="$emit('urlSaved', updatedContentItem)" v-model="source.sourceUrl" id="sourceName" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="http://example.com"/>-->
                                    </div>
                                    <div class="col-span-1 sm:col-span-1">
                                        <button type="button" @click="deleteContentSource(source.JID)" class="flex font-medium text-white hover:text-indigo-500 focus:outline-none focus:underline-none transition duration-150 ease-in-out items-center">
                                            <svg class=" mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
<!--                                                <svg viewBox="0 0 20 20" fill="currentColor" class="minus w-6 h-6"><path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>-->
                                                <svg viewBox="0 0 20 20" fill="currentColor" class="minus-circle w-6 h-6"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" fill="#fc5c69"></path></svg>
<!--                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H9V13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13V11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H11V7Z" fill="#fc5c69"/>                                                            &lt;!&ndash;                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />&ndash;&gt;-->
                                                <!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM4.33179 8.02741C4.70542 6.95361 5.37558 6.01864 6.24421 5.32056C6.51209 5.72966 6.97449 5.99991 7.50001 5.99991C8.32844 5.99991 9.00001 6.67148 9.00001 7.49991V7.99991C9.00001 9.10448 9.89545 9.99991 11 9.99991C12.1046 9.99991 13 9.10448 13 7.99991C13 7.05979 13.6487 6.27118 14.5228 6.05719C15.4428 7.11161 16 8.49069 16 9.99992C16 10.3407 15.9716 10.6748 15.917 11.0001H15C13.8954 11.0001 13 11.8955 13 13.0001V15.1973C12.1175 15.7078 11.0928 15.9999 9.99992 15.9999V14C9.99992 12.8954 9.10448 12 7.99992 12C6.89535 12 5.99992 11.1046 5.99992 10C5.99992 9.00849 5.27841 8.1855 4.33179 8.02741Z"/>-->
                                            </svg>
                                        </button>
                                        <!--                                                                <input @focusout="$emit('urlSaved', updatedContentItem)" v-model="source.sourceUrl" id="sourceName" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="http://example.com"/>-->
                                    </div>
                                    <div class="col-span-2 my-2 border-2"></div>
                                </div>

                                <div class="col-span-1 sm:col-span-1 mt-2">
                                    <button type="button" @click="addSource" class="flex font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline-none transition duration-150 ease-in-out items-center">
                                        <svg class=" mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H9V13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13V11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H11V7Z" fill="#18D300"/>                                                            <!--                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />-->
                                            <!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM4.33179 8.02741C4.70542 6.95361 5.37558 6.01864 6.24421 5.32056C6.51209 5.72966 6.97449 5.99991 7.50001 5.99991C8.32844 5.99991 9.00001 6.67148 9.00001 7.49991V7.99991C9.00001 9.10448 9.89545 9.99991 11 9.99991C12.1046 9.99991 13 9.10448 13 7.99991C13 7.05979 13.6487 6.27118 14.5228 6.05719C15.4428 7.11161 16 8.49069 16 9.99992C16 10.3407 15.9716 10.6748 15.917 11.0001H15C13.8954 11.0001 13 11.8955 13 13.0001V15.1973C12.1175 15.7078 11.0928 15.9999 9.99992 15.9999V14C9.99992 12.8954 9.10448 12 7.99992 12C6.89535 12 5.99992 11.1046 5.99992 10C5.99992 9.00849 5.27841 8.1855 4.33179 8.02741Z"/>-->
                                        </svg>
                                        Add a source
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-10 px-4 py-3 bg-white text-right sm:px-6 border-2 rounded-md border-red-300 items-center">
            <span class="flex justify-between rounded-md">
                    <input v-model="deleteInput" id="delete" class="form-input block w-3/6 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Type 'delete' for deletion"/>
                <button @click="deleteContentItem" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out">
                Delete
              </button>
            </span>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
    import _ from 'lodash';
    import {directive as onClickaway} from 'vue-clickaway'

    export default {
        name: "ContentDetails",
        directives: {
            onClickaway
        },
        props: ['open', 'contentItem', 'listID'],
        data() {
            return {
                lastRequest: null,
                updatedContentItem: {
                    title: null,
                    level: null,
                    authors: null,
                    description: null,
                    mediaType: null,
                    thumbnail: null,
                    contentId: null,
                    contentUrl: null,
                    sources: null,
                },
                deleteInput: null,
            }
        },
        created() {
            this.updatedContentItem = _.cloneDeep(this.contentItem)
        },
        watch: {
            contentItem (newContentItem){
                console.log("Changed")
                this.updatedContentItem = _.cloneDeep(newContentItem)
            }
        },
        methods: {
            deleteContentSource(sourceID){
                this.$emit("deleteContentSource", sourceID, this.updatedContentItem.JID, this.listID)
            },
            updateContentSource: _.debounce(function (source) {
                console.log("Just updatin'")
                this.$emit('updateContentSource', source ,this.updatedContentItem.JID, this.listID)
            }, 1000),
            updateInputs: _.debounce(function () {
                this.$emit('updateContentInputs', this.updatedContentItem, this.listID)
            }, 1000),
            close() {
                this.deleteInput = ""
                // this.updateContentItem()
                this.$emit('close')
            },
            deleteContentItem(){
                if(this.deleteInput === "delete") {
                    this.$emit('deleteContentItem', this.updatedContentItem.JID, this.listID)
                    this.deleteInput = '';
                    this.$emit('close')
                }
            },
            updateContentItem(){
                // const newSources = _.filter(this.updatedContentItem.sources, (source) => {
                //     return !((source.title.trim()) === "" && (source.url.trim()) === "")
                // });
                // this.updatedContentItem.sources = newSources;
                this.$emit('updateContentItem', this.updatedContentItem, this.listID)

            },
            addSource(){
                // console.log("Updated content item is: ", this.updatedContentItem);
                const newSource = {
                    title: "",
                    url:  "",
                };
                this.updatedContentItem.sources.push(newSource)
                this.$emit('newContentSource', newSource ,this.updatedContentItem.JID, this.listID)
            },
        },
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed !important;
        z-index: 2000 !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
        overflow-y: auto !important;
        webkit-transform: translate3d(0,0,0) !important;
        background-color: rgba(255, 255, 255, 0.8) !important;
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        transition: opacity .3s ease;
    }
</style>



<!--                                                    <div class="col-span-6 sm:col-span-3">-->
<!--                                                        <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>-->
<!--                                                        <input id="last_name" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />-->
<!--                                                    </div>-->

<!--                                                    <div class="col-span-6 sm:col-span-4">-->
<!--                                                        <label for="about" class="block text-sm leading-5 font-medium text-gray-700">-->
<!--                                                            Description-->
<!--                                                        </label>-->
<!--                                                        <div class="rounded-md shadow-sm">-->
<!--                                                            <textarea id="about" rows="3" class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>-->
<!--                                                        </div>-->
<!--                                                        <p class="mt-2 text-sm text-gray-500">-->
<!--                                                            Brief description for your profile. URLs are hyperlinked.-->
<!--                                                        </p>-->
<!--                                                    </div>-->




<!--                                                    <div class="col-span-6">-->
<!--                                                        <label for="street_address" class="block text-sm font-medium leading-5 text-gray-700">Street address</label>-->
<!--                                                        <input id="street_address" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />-->
<!--                                                    </div>-->

<!--                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">-->
<!--                                                        <label for="city" class="block text-sm font-medium leading-5 text-gray-700">City</label>-->
<!--                                                        <input id="city" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />-->
<!--                                                    </div>-->

<!--                                                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">-->
<!--                                                        <label for="state" class="block text-sm font-medium leading-5 text-gray-700">State / Province</label>-->
<!--                                                        <input id="state" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />-->
<!--                                                    </div>-->

<!--                                                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">-->
<!--                                                        <label for="postal_code" class="block text-sm font-medium leading-5 text-gray-700">ZIP / Postal</label>-->
<!--                                                        <input id="postal_code" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />-->
<!--                                                    </div>-->
